/* change the link color */
.navbar-custom .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.nav-item .active {
  color: rgba(255, 255, 255, 0.8);
}

/* svi linkovi u nav baru u velika slova i poravnanje sa dugmetom CLIENT AREA */
.nav-item {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.6;
}

.jezikbtn {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 2;
  color: rgba(255, 255, 255, 1) !important;
}

/* change hamburger ikonice kada je zatvoren menu */
.navbar-dark .navbar-toggler.collapsed .navbar-toggler-icon {
  transition: 0.3s;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='1' fill='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' d='M 29.598214,5.491072 H 0.40178571 A 0.40178571,0.40178571 0 0 1 0,5.089286 V 3.482143 A 0.40178571,0.40178571 0 0 1 0.40178571,3.080357 H 29.598214 A 0.40178571,0.40178571 0 0 1 30,3.482143 v 1.607143 a 0.40178571,0.40178571 0 0 1 -0.401786,0.401786 z m 0,10.714285 H 0.40178571 A 0.40178571,0.40178571 0 0 1 0,15.803572 V 14.196429 A 0.40178571,0.40178571 0 0 1 0.40178571,13.794643 H 29.598214 A 0.40178571,0.40178571 0 0 1 30,14.196429 v 1.607143 a 0.40178571,0.40178571 0 0 1 -0.401786,0.401785 z m 0,10.714286 H 0.40178571 A 0.40178571,0.40178571 0 0 1 0,26.517857 V 24.910714 A 0.40178571,0.40178571 0 0 1 0.40178571,24.508929 H 29.598214 A 0.40178571,0.40178571 0 0 1 30,24.910714 v 1.607143 a 0.40178571,0.40178571 0 0 1 -0.401786,0.401786 z'/%3E%3C/svg%3E");
}

/* change hamburger ikonice kada je otvoren menu */
.navbar-dark .navbar-toggler .navbar-toggler-icon {
  transition: 0.3s;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' fill='rgba(255, 255, 255, 1)' stroke-width='0' stroke-linecap='round' stroke-miterlimit='10' d='m 18.182443,15.000469 9.615301,-9.615301 1.982874,-1.982874 c 0.292509,-0.292509 0.292509,-0.767837 0,-1.060346 L 27.658989,0.220319 c -0.292509,-0.292509 -0.767836,-0.292509 -1.060345,0 L 15.000469,11.818494 3.4022939,0.219382 c -0.2925091,-0.292509 -0.7678365,-0.292509 -1.0603456,0 L 0.21938199,2.341011 c -0.29250915,0.292509 -0.29250915,0.767836 0,1.060345 L 11.818494,15.000469 0.21938199,26.598644 c -0.29250915,0.292509 -0.29250915,0.767836 0,1.060345 l 2.12162881,2.121629 c 0.2925091,0.292509 0.7678365,0.292509 1.0603456,0 l 11.5991126,-11.598175 9.6153,9.615301 1.982875,1.982874 c 0.292509,0.292509 0.767836,0.292509 1.060345,0 l 2.121629,-2.121629 c 0.292509,-0.292509 0.292509,-0.767836 0,-1.060345 z'/%3E%3C/svg%3E");
}

/* ugasen hamburger outline ruzan narandjast */
#dugmezatvaranje:focus {
  outline: none;
}

/* ugasen hamburger border */
.navbar-toggler {
  border: none;
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .navbar-collapse {
    background-color: #000f80;
    text-align: center;
    transition: 0.3s;
  }

  .navbar {
    padding: 0;
  }

  .promjenaboje {
    background-color: #000f80;
  }

  .navbar-toggler {
    margin-right: 15px;
  }

  .navbar-brand {
    padding-top: 15px;
    padding-left: 15px;
  }

  .er-vh {
    min-height: 100vh;
  }
}
