.form-label,
.er-btn-font,
strong {
  font-family: 'DINPro Bold';
}

.er-text-area {
  min-height: 5.375rem;
}

.fade:not(.show) {
  display: none;
}

.toast {
  z-index: 1000;
  min-width: 310px;
}

.er-btn-font:disabled {
  cursor: not-allowed;
}
