$theme-colors: (
  'primary': #000f80,
  'secondary': #bd2981,
  'light': #fff,
  'doc-primary-light': #2c3999,
  'doc-red': #fb4050,
  'doc-pink': #bd2981,
  'doc-pink-dark': #a62673,
  'doc-light-blue': #39abd4,
  'doc-light-gray': #abbfc6,
  'doc-client-area': #438198,
  'doc-iug-plava': #1e376f,
  'doc-iug-orange': #e39f2b,
  'doc-iug-tirkiz': #25a19d,
  'doc-iug-crvena': #de2a3f,
  'doc-iug-gray': #ededed,
  'doc-poliglot-plava': #1b3d68,
  'doc-poliglot-orange': #f77e27,
  'doc-pomoziba-primary': #fd5300,
  'doc-pomoziba-secondary': #232121,
  'doc-drv-plava': #0092d0,
  'doc-drv-purple': #4a105c,
  'doc-metadata-siva': #2c4251,
  'doc-metadata-plava': #1a2a7a,
  'doc-occ-primary': #990000,
  'doc-occ-secondary': #5c5e6b,
  'doc-ipadram-primary': #3a65b0,
  'doc-ipadram-secondary': #eb262a,
  'doc-repr-primary': #d9391e,
  'doc-repr-secondary': #495057,
  'doc-hrf-primary': #0083cb,
  'doc-hrf-secondary': #334e8a,
  'doc-nosa-primary': #2d3b60,
  'doc-nosa-secondary': #384b7d,
  'doc-stilles-primary': #8c7c72,
  'doc-stilles-secondary': #ad9688,
  'doc-light-oker':
    rgba(
      $color: #d94350,
      $alpha: 0.1,
    ),
  'doc-dark':
    rgba(
      $color: #000,
      $alpha: 0.3,
    ),
);
