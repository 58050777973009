#about .client-area-cta {
  color: #000f80;
}

.ot-opacity {
  opacity: 0.6;
}

// Profilne-cta animacija on hover
.profilne-kocka {
  position: absolute;
  background-color: #fff;
  width: 24.5px;
  height: 24.5px;
  border-radius: 0.15rem;
}

#profilne-cta {
  width: 100%;
  cursor: pointer;
  position: absolute;
}

#profilne-cta .er-arrow {
  left: 12.5%;
}

.profilne-rmbtn {
  margin-left: 2.1rem;
  margin-top: 0.09rem;
}

.er-arrow {
  position: absolute;
  bottom: 0;
  margin-left: 0px;
  width: 12px;
  height: 12px;
  background-size: contain;
  top: 1.5px;
}
.druga {
  margin-left: 8px;
}

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

.fix-profilne-btn {
  margin-bottom: 0.1rem;
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.er-arrow.prva.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.profilne-main:hover .er-arrow {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.profilne-main:hover .er-arrow.prva {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .fix-h2-recent-projects {
    text-align: center;
  }
}

/* Galaxy S5 in landscape */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: landscape) {
  .fix-container-ot1 {
    margin-top: 0 !important;
  }
}

/* iPhone 6, 7, & 8 in landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  .fix-container-ot1 {
    margin-top: 0 !important;
  }
}

/* Pixel2 in landscape */
@media only screen and (min-device-width: 411px) and (max-device-width: 823px) and (orientation: landscape) {
  .fix-container-ot1 {
    margin-top: -8.75rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .fix-container-ot1 {
    margin-top: -9.75rem;
  }

  .section-title-ot1 {
    min-height: 35.625rem;
  }

  .section-kocka-ot1 {
    min-height: 25rem;
    max-width: 28.125rem;
  }

  .section-title-ot2,
  .section-title-ot1m {
    min-height: 20rem;
  }

  .section-kocka-ot2 {
    min-height: 20rem;
  }

  .fix-container-ot-plavi {
    margin-bottom: -4rem;
  }

  .fix-div-ot-plavi {
    height: 4rem;
  }
}

/* iPhone X in landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .fix-container-ot1 {
    margin-top: -8.75rem;
  }
}

/* iPad in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .fix-container-ot1 {
    margin-top: -8.75rem;
  }
}

/* iPad-Pro in portrait */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .fix-container-ot1 {
    margin-top: -9.75rem;
  }
}

// Extra small devices (portrait phones, less than 359.98px)
@media (max-width: 359.98px) and (orientation: portrait) {
  .small {
    font-size: 60%;
  }
  .profilne-rmbtn {
    font-size: 0.75rem;
  }
  .profilne-linkedin {
    font-size: 0.75rem;
  }
  h1 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
}
