$primary: lighten(#6493a6, 30%);

.widget {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.widget-vh-center {
  align-items: center;
  justify-content: center;
}

.widget-v-center {
  align-items: center;
  //justify-content: center;
}

.widget-vc-left {
  align-items: flex-start;
  justify-content: center;
}

.widget-vc-right {
  align-items: flex-end;
  justify-content: center;
}
