.footer-link,
.footer-ikonice,
.footer-text {
  color: #90a4ae;
}

.footer-text-mob {
  color: rgba(144, 164, 174, 0.5);
}

.footer-link,
.footer-text {
  font-size: 12px;
}

a.footer-link {
  text-decoration: none;
}

a.footer-link:hover {
  color: #fff;
  transition: all 0.5s;
}

a.footer-ikonice:hover {
  opacity: 0.6;
  transition: all 0.5s;
}

.footer-div {
  vertical-align: middle;
  border-top: 1px solid rgba(144, 164, 174, 0.5);
  border-bottom: 1px solid rgba(144, 164, 174, 0.5);
  width: calc(100vw - 30px);
  text-align: center;
}

.footer-span {
  vertical-align: middle;
  line-height: 1.5rem;
  border-right: 2px solid rgba(144, 164, 174, 0.5);
  padding: 0 0.5rem;
}
.footer-span-p {
  vertical-align: middle;
  line-height: 1.5rem;
  border-right: 2px solid rgba(144, 164, 174, 0.5);
  padding-right: 0.5rem;
}

.footer-span-z {
  vertical-align: middle;
  line-height: 1.5rem;
  padding-left: 0.5rem;
}

.fix-mob-totop .totop {
  top: -0.5rem;
  right: 0.25rem;
  background-color: transparent;
  color: #90a4ae;
}

.fix-mob-totop .totop:focus {
  background-color: transparent;
}
