#services .client-area-cta {
  color: #bd2981;
}

.title-ikonice {
  color: rgba($color: #fff, $alpha: 0.85);
}

.card img.card-img-top {
  max-width: 130px;
}

.er-mr-n6 {
  margin-top: -2.2rem;
}
