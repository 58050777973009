#our-work .client-area-cta {
  color: #fb4050;
}

.title-ikonice {
  color: rgba($color: #fff, $alpha: 0.85);
}

.img-efect {
  overflow: hidden;
}

.img-efect .stretched-link img {
  transition: all 0.5s;
}

.img-efect .stretched-link:hover img {
  transition: all 0.5s;
  transform: scale(1.3);
}

.card-group .card img {
  max-height: 285px;
}

.card img.card-img-top {
  max-width: 130px;
}

.ovc_discover__image {
  max-width: 130px;
  padding: 10px 0;
}

a .ovc_discover__image {
  filter: brightness(50%) sepia(1) hue-rotate(152deg) saturate(103.2%)
    brightness(91.2%);
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .fix-h2-recent-projects {
    text-align: center;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .section-title-ow1 {
    min-height: 35.625rem;
  }

  .section-kocka-ow1 {
    min-height: 25rem;
    max-width: 28.125rem;
  }

  .section-title-ow2 {
    min-height: 25rem;
  }

  .section-kocka-ow2 {
    min-height: 25rem;
    max-width: 28.125rem;
  }

  .fix-container-ow-oker {
    margin-bottom: -6rem;
  }

  .fix-div-ow-oker {
    height: 6rem;
  }
}
