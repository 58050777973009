.totop {
  position: absolute;
  right: 0;
  top: -1.9rem;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  color: white;
  background-color: #90a4ae;
  height: 40px;
  width: 40px;
}

.totop:hover {
  background-color: #6c757d;
  transition: all 0.6s;
}
