/* DINPro Fonts */

@font-face {
font-family: 'DINPro Light';
font-style: normal;
font-weight: normal;
src: local('DINPro Light'), url('./DINPro-Light.woff') format('woff');
}


@font-face {
font-family: 'DINPro Medium';
font-style: normal;
font-weight: normal;
src: local('DINPro Medium'), url('./DINPro-Medium.woff') format('woff');
}


@font-face {
font-family: 'DINPro Bold';
font-style: normal;
font-weight: normal;
src: local('DINPro Bold'), url('./DINPro-Bold.woff') format('woff');
}


@font-face {
font-family: 'DINPro Black';
font-style: normal;
font-weight: normal;
src: local('DINPro Black'), url('./DINPro-Black.woff') format('woff');
}