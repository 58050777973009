#home .client-area-cta {
  color: #fff;
  background-color: #000f80;
  border-color: #000f80;
}

#home .client-area-cta:hover {
  background-color: #000b5a;
  border-color: #00094d;
}

#home .client-area-cta:focus {
  color: #fff;
  background-color: #000b5a;
  border-color: #00094d;
  box-shadow: 0 0 0 0.2rem rgba(38, 51, 147, 0.5);
}

#home .navbar-collapse.collapse.show .client-area-cta {
  background-color: #bd2981;
  border-color: #bd2981;
}

.col-md-0-x5 {
  width: 4.16667%;
}

.col-md-11-x5 {
  width: 100%;
}

.home-text-dugme {
  margin-left: 30px;
}

.er-btn-dow {
  background-color: #bd2981;
  border-color: #bd2981;
}

.er-iconaddress,
.er-iconworktime,
.er-iconphone,
.er-iconemail {
  max-width: 50%;
  font-size: 0.9rem;
}

.er-iconaddress {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.er-iconworktime {
  border-bottom: 1px solid #fff;
}

.er-iconphone {
  border-right: 1px solid #fff;
}

.er-iconaddress:hover,
.er-iconworktime:hover,
.er-iconphone:hover,
.er-iconemail:hover {
  background-color: #6c757d21;
}

.er-iconaddress:hover {
  border-top-left-radius: 2rem;
}

.er-iconworktime:hover {
  border-top-right-radius: 2rem;
}

.er-iconphone:hover {
  border-bottom-left-radius: 2rem;
}

.er-iconemail:hover {
  border-bottom-right-radius: 2rem;
}

.discover__image {
  max-width: 105px;
  padding: 10px 0;
}

.discover__image_svg {
  max-height: 85px;
  padding: 10px 0 3px;
}

.title-ikonice {
  margin-top: 1.5rem;
}

.rocket__img {
  margin-left: -2.2rem;
  width: 100%;
}

.fill-class-home p {
  font-family: 'DINPro Medium';
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .col-md-0-x5 {
    width: 2.08333%;
  }

  .col-md-11-x5 {
    width: 97.91666%;
  }

  .home-owerlap {
    position: relative;
    margin-top: -40px;
  }

  .fix-container-home {
    margin-top: -200px;
  }

  .fix-colh {
    padding: 100px;
  }

  .fix-drugi-red {
    margin-top: -240px;
  }

  .fix-col-drugired1 {
    padding: 120px;
  }

  .fix-col-drugired2 {
    padding: 99px;
  }

  .fix-drugi-red .widget-vh-center {
    border-left: 35px solid #ffffff;
  }

  .fix-client-area {
    min-height: 253px;
  }

  .home-text-dugme {
    margin-left: 0;
  }

  .er-btn-dow {
    background-color: #000f80;
    border-color: #000f80;
  }

  .er-iconaddress,
  .er-iconworktime,
  .er-iconphone,
  .er-iconemail {
    max-width: 49%;
  }

  .discover__image {
    max-width: 155px;
  }

  .discover__image_svg {
    max-height: 110px;
  }
}

/* iPhone 5/SE in portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
  .home-text-dugme {
    margin-top: 7rem;
  }

  .home-text-dugme h1 {
    font-size: 8vw;
  }

  .home-text-dugme h3 {
    font-size: 6vw;
  }

  .er-btn-dow {
    font-size: 3vw;
  }

  .title-ikonice a {
    font-size: 3vw;
  }

  .discover__image {
    max-width: 110px;
  }
}

/* Galaxy S5 in portrait */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
  .home-text-dugme {
    margin-top: 7.5rem;
  }

  .home-text-dugme h1 {
    font-size: 8vw;
  }

  .home-text-dugme h3 {
    font-size: 6vw;
  }

  .er-btn-dow {
    font-size: 3vw;
  }

  .title-ikonice a {
    font-size: 3vw;
  }

  .discover__image {
    max-width: 130px;
  }

  .discover__image_svg {
    max-height: 100px;
  }
}

/* Galaxy S5 in landscape */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: landscape) {
  .discover__image {
    max-width: 155px;
  }

  .discover__image_svg {
    max-height: 110px;
  }
}

/* iPhone 6, 7, & 8 in portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .home-text-dugme {
    margin-top: 7rem;
  }
}

/* iPhone 6, 7, & 8 and Galaxy S5 in landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  .home-text-dugme {
    margin-top: 3rem;
  }

  .home-text-dugme h1 {
    font-size: 4vw;
  }

  .home-text-dugme h3 {
    font-size: 2.5vw;
  }

  .er-btn-dow {
    font-size: 2vw;
  }

  .title-ikonice a {
    font-size: 1.5vw;
  }
}

/* iPhone 6, 7, & 8 and Galaxy S5 in landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  .discover__image {
    max-width: 155px;
  }

  .discover__image_svg {
    max-height: 110px;
  }
}

/* iPhone 6, 7, & 8 and Galaxy S5 in portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: portrait) {
  .discover__image {
    max-width: 150px;
  }

  .discover__image_svg {
    max-height: 105px;
  }
}

/* iPhone X in portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .rocket__img {
    margin-left: -1.5rem;
    max-width: 100%;
  }
}

/* iPhone X in landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .rocket__img {
    margin-left: -1rem;
    max-width: 105%;
  }

  .home-text-dugme {
    margin-top: 2rem;
  }

  .home-text-dugme h1 {
    font-size: 3vw;
  }

  .title-ikonice {
    margin-top: 1rem;
  }
}

/* Pixel2 in landscape */
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (orientation: landscape) {
  .home-text-dugme h1 {
    font-size: 4vw;
  }

  .home-text-dugme h3 {
    font-size: 2.5vw;
  }

  .er-btn-dow {
    font-size: 2vw;
  }

  .title-ikonice a {
    font-size: 1.5vw;
  }
}

/* Pixel2 in portrait */
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (orientation: portrait) {
  .home-text-dugme {
    margin-top: 7.5rem;
  }
}

/* iPad in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .fix-drugi-red .widget-vh-center {
    border-left: 27px solid #ffffff;
  }

  .discover__image {
    max-width: 130px;
  }

  .discover__image_svg {
    max-height: 100px;
  }
}

/* Pixel2 XL in portrait */
@media only screen and (min-device-width: 411px) and (max-device-width: 823px) and (orientation: portrait) {
  .discover__image {
    max-width: 150px;
  }
}

/* Pixel2 XL in landscape */
@media only screen and (min-device-width: 411px) and (max-device-width: 823px) and (orientation: landscape) {
  .home-text-dugme {
    margin-top: 0;
  }

  .home-text-dugme h1 {
    font-size: 3.5vw;
  }

  .home-text-dugme h3 {
    font-size: 2.5vw;
  }

  .er-btn-dow {
    font-size: 1.5vw;
  }

  .title-ikonice a {
    font-size: 1.5vw;
  }

  .discover__image {
    max-width: 160px;
  }
}

/* iPad in portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  header {
    max-height: 50vh;
  }

  .fix-row-home.vh-100 {
    max-height: 50vh;
  }

  .home-owerlap {
    margin-top: -40px;
  }

  .home-text-dugme h1 {
    font-size: 4vw;
  }

  .home-text-dugme h3 {
    font-size: 3vw;
  }

  .er-btn-dow {
    font-size: 2vw;
  }

  .title-ikonice a {
    font-size: 1.5vw;
  }

  .rocket__img {
    margin-left: -6.5rem;
    min-width: 125%;
  }
}

/* iPad in landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  header {
    max-height: 75vh;
  }

  .fix-row-home.vh-100 {
    max-height: 75vh;
  }

  .home-owerlap {
    margin-top: -40px;
  }

  .home-text-dugme h1 {
    font-size: 3.5vw;
  }

  .home-text-dugme h3 {
    font-size: 2.5vw;
  }

  .er-btn-dow {
    font-size: 1.5vw;
  }

  .title-ikonice a {
    font-size: 1vw;
  }

  .rocket__img {
    margin-left: -2rem !important;
    min-width: 104% !important;
  }
}

/* iPad Pro in portrait */
@media (min-width: 1024px) and (max-width: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  header {
    max-height: 50vh;
  }

  .fix-row-home.vh-100 {
    max-height: 50vh;
  }

  .rocket__img {
    margin-left: -7rem;
    min-width: 122%;
  }
}

/* iPad-Pro in landscape */
@media (min-width: 1024px) and (max-width: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  header {
    max-height: 75vh;
  }

  .fix-row-home.vh-100 {
    max-height: 75vh;
  }

  .rocket__img {
    margin-left: 0;
    min-width: 100%;
  }
}
