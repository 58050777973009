#contact .client-area-cta {
  color: #39abd4;
}

.fill-class-contact p {
  font-family: 'DINPro Medium';
}

.info-tingea p {
  font-family: 'DINPro Medium';
}

/* iPad in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .fix-iznad-scroll {
    margin-bottom: 0 !important;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .form-contact {
    padding: 1rem;
    margin-bottom: 1rem;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .form-contact {
    margin-bottom: 2rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .er-4icons-contact {
    margin-top: 0.25rem;
    margin-left: 1rem;
  }
}
