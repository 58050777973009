/* DEFAULT FONT Lato nema slovo Č pa sam stavio ovaj kao defaultni */
* {
  font-family: 'Open Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 0.75em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* DINPro fontovi za naslove i podnaslove H1-H6 */
.dinpro-light {
  font-family: 'DINPro Light';
}

.dinpro-medium {
  font-family: 'DINPro Medium';
}

.dinpro-bold {
  font-family: 'DINPro Bold';
}

/* Carousel */
.fix-container-carousel {
  display: flex;
}

.btn-groupx button:focus,
.btn-groupx button:active,
.btn-groupx button:visited,
.btn-groupx .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-groupx .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-groupx .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

/* Our Work animacija za load more button */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.7s ease-in;
}

/* Client-area button prema ID headera */
.client-area-cta {
  font-weight: 700;
}

.portfolio-m .client-area-cta {
  color: #000f80;
}

.portfolio-z .client-area-cta {
  color: #bd2981;
}

/* STRIKE both side */
.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

/* STRIKE right side */
.strike-r {
  display: block;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}

.strike-r > span {
  position: relative;
  display: inline-block;
}

.strike-r > span:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
}

.strike-r > span:after {
  left: 100%;
  margin-left: 15px;
}

/* STRIKE left side */
.strike-l {
  display: block;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
}

.strike-l > span {
  position: relative;
  display: inline-block;
}

.strike-l > span:before {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
}

.strike-l > span:before {
  right: 100%;
  margin-right: 15px;
}

/* Strike boje */
.strike > span.strike-w:before,
.strike > span.strike-w:after,
.strike-l > span.strike-w:before,
.strike-r > span.strike-w:after {
  background: #fff;
}

.strike > span.strike-b:before,
.strike > span.strike-b:after,
.strike-l > span.strike-b:before,
.strike-r > span.strike-b:after {
  background: #343a40;
}

/* KRAJ STRIKE */

.home-social {
  position: absolute;
  right: 0;
  // bottom: 0 !important;
  z-index: 1;
  top: calc(100vh - 34.95px);
}

.er-mr-n6 {
  margin-top: -2.15rem;
}

.social-home-icons {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.fix-iznad-scroll {
  margin-bottom: 2rem;
  max-height: 14.5rem;
}

.dugmedole {
  line-height: 1;
}

.dugmedole .svg-inline--fa {
  vertical-align: -0.065em;
}

.fix-strelica .svg-inline--fa {
  vertical-align: 0;
}

.span-ikonica-fa {
  font-size: 1.75rem;
}

.container h1 {
  line-height: 1.2;
}

.fix-container-home,
.fix-container-above-footer,
.home-owerlap {
  padding-left: 0;
  padding-right: 0;
}

#sdtsos:hover {
  opacity: 0.75;
  transition: all 0.2s ease-in-out;
}

p {
  font-size: 0.925rem;
}

.box-ipp-relevant-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

.slika-lightboox {
  cursor: pointer;
}

.ipp-testemonial img {
  max-width: 100px;
}

.ipp-ikon-kocka {
  padding: 0.3rem 0.5rem;
  width: 2rem;
  height: 2rem;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .section-title-ipp {
    min-height: 35.625rem;
  }

  .section-kocka-ipp {
    min-height: 25rem;
    max-width: 28.125rem;
  }
}

// Small devices (phones, less than 480px)
@media (max-width: 480px) {
  header {
    height: 100vh !important;
  }

  .small {
    font-size: 70%;
  }
}
// Small devices (phones, less than 658px)
@media (min-height: 640px) and (orientation: portrait) {
  header {
    height: 100vh !important;
  }

  .small {
    font-size: 70%;
  }
}

// Small devices (phones, less than 658px)
@media (min-height: 658px) and (orientation: portrait) {
  header {
    height: 100vh !important;
  }

  .small {
    font-size: 70%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .box-ipp-relevant-link a {
    font-size: 0.7rem;
  }

  .ipp-title-ikonice img {
    max-height: 50px;
    max-width: 100%;
  }

  .ipp-relevant-links-ikonice img {
    max-height: 40px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  p {
    font-size: initial;
  }

  .fix-container-home,
  .fix-container-above-footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  .fix-strelica .svg-inline--fa {
    vertical-align: 0.035em;
  }

  .span-ikonica-fa {
    font-size: 2.125rem;
  }

  h1.dinpro-bold,
  h1.dinpro-light {
    font-size: 3.25rem;
  }

  h2.dinpro-bold,
  h2.dinpro-light {
    font-size: 2.75rem;
  }

  .container h1 {
    line-height: 1;
  }

  .section-title {
    min-height: 37.5rem;
  }

  .section-kocka {
    min-height: 28rem;
    max-width: 28rem;
  }

  .fix-totop .totop {
    top: -4.4rem;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fix-totop .totop {
    top: -4.4rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  header {
    max-height: 34.5rem;
  }

  .header-main h1 {
    font-size: 2.2rem;
  }

  .header-main h3 {
    font-size: 1.5rem;
  }

  .fix-container-home,
  .fix-container-above-footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  p {
    font-size: initial;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fix-totop .totop {
    top: -4.4rem;
  }

  .header-main h1 {
    font-size: 2.2rem;
  }

  .header-main h3 {
    font-size: 1.5rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  header {
    max-height: 36rem;
  }

  .fix-container-home,
  .fix-container-above-footer {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/* iPad in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .fix-iznad-scroll {
    margin-bottom: 0 !important;
  }

  .fix-drugi-red .widget-vh-center {
    border-left: 113px solid #ffffff !important;
  }
}

// PROFILNE SA OKRUGLOM SLIKOM GLOBAL
a.team-ikonice-btn:hover {
  opacity: 0.9;
}

.fix-okrugla-slika {
  overflow-x: hidden;
}

.border-outer {
  position: relative;
  border-top: 6px solid #e6e6e6;
  border-left: 6px solid #e6e6e6;
  border-right: 6px solid #000f80;
  border-bottom: 6px solid #e6e6e6;
  border-radius: 50%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  overflow: hidden !important;
}
.border-iner {
  position: relative;
  border-top: 6px solid rgba(230, 230, 230, 0.3);
  border-left: 6px solid rgba(230, 230, 230, 0.3);
  border-right: 6px solid rgba(0, 15, 152, 0.6);
  border-bottom: 6px solid rgba(230, 230, 230, 0.3);
  border-radius: 50%;
  overflow: hidden !important;
}

.okrugla-slika {
  max-width: 264px;
  max-height: 264px;
}
.border-outer {
  width: 266px;
  height: 266px;
}
.border-iner {
  width: 254px;
  height: 254px;
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .okrugla-slika {
    max-width: 375px;
    max-height: 375px;
  }
  .border-outer {
    width: 377px;
    height: 377px;
  }
  .border-iner {
    width: 365px;
    height: 365px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .okrugla-slika {
    max-width: 375px;
    max-height: 375px;
  }
  .border-outer {
    width: 377px;
    height: 377px;
  }
  .border-iner {
    width: 365px;
    height: 365px;
  }
}

/* iPadMini in portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  .okrugla-slika {
    max-width: 236px;
    max-height: 236px;
  }
  .border-outer {
    width: 238px;
    height: 238px;
  }
  .border-iner {
    width: 226px;
    height: 226px;
  }
}

/* iPadMini in landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .okrugla-slika {
    max-width: 318px;
    max-height: 318px;
  }
  .border-outer {
    width: 320px;
    height: 320px;
  }
  .border-iner {
    width: 308px;
    height: 308px;
  }
}

/* Pixel 2 in landscape */
@media only screen and (min-width: 411px) and (max-width: 823px) and (-webkit-device-pixel-ratio: 3.5) and (orientation: landscape) {
  .fix-totop .totop {
    top: -4.35rem;
  }
  .okrugla-slika {
    max-width: 238px;
    max-height: 238px;
  }
  .border-outer {
    width: 240px;
    height: 240px;
  }
  .border-iner {
    width: 228px;
    height: 228px;
  }
}

/* Galaxy S III in oba */
@media only screen and (min-width: 360px) and (max-width: 640px) and (-webkit-device-pixel-ratio: 2) {
  .okrugla-slika {
    max-width: 244px;
    max-height: 244px;
  }
  .border-outer {
    width: 246px;
    height: 246px;
  }
  .border-iner {
    width: 234px;
    height: 234px;
  }
}

/* iPhone X landscape */
@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .okrugla-slika {
    max-width: 238px;
    max-height: 238px;
  }
  .border-outer {
    width: 240px;
    height: 240px;
  }
  .border-iner {
    width: 228px;
    height: 228px;
  }
}

/* iPhone 6/7/8 landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .okrugla-slika {
    max-width: 263px;
    max-height: 263px;
  }
  .border-outer {
    width: 265px;
    height: 265px;
  }
  .border-iner {
    width: 253px;
    height: 253px;
  }
}

/* iPhone 6/7/8 Plus portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .okrugla-slika {
    max-width: 314px;
    max-height: 314px;
  }
  .border-outer {
    width: 316px;
    height: 316px;
  }
  .border-iner {
    width: 304px;
    height: 304px;
  }
}

/* iPhone 6/7/8 Plus in landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .okrugla-slika {
    max-width: 264px;
    max-height: 264px;
  }
  .border-outer {
    width: 266px;
    height: 266px;
  }
  .border-iner {
    width: 254px;
    height: 254px;
  }
}

/* Pixel 2 in landscape */
@media only screen and (min-width: 411px) and (max-width: 731px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .okrugla-slika {
    max-width: 144px;
    max-height: 144px;
  }
  .border-outer {
    width: 146px;
    height: 146px;
  }
  .border-iner {
    width: 134px;
    height: 134px;
  }
}

/* Galaxy S5 in oba */
@media only screen and (min-width: 360px) and (max-width: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .okrugla-slika {
    max-width: 264px;
    max-height: 264px;
  }
  .border-outer {
    width: 266px;
    height: 266px;
  }
  .border-iner {
    width: 254px;
    height: 254px;
  }
}

/* Galaxy S9 in oba */
@media only screen and (min-width: 320px) and (max-width: 658px) and (-webkit-device-pixel-ratio: 4.5) {
  .okrugla-slika {
    max-width: 264px;
    max-height: 264px;
  }
  .border-outer {
    width: 266px;
    height: 266px;
  }
  .border-iner {
    width: 254px;
    height: 254px;
  }
}

/* iPhone SE in portrait */
@media only screen and (min-width: 320px) and (max-width: 567.98px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  .okrugla-slika {
    max-width: 278px;
    max-height: 278px;
  }
  .border-outer {
    width: 280px;
    height: 280px;
  }
  .border-iner {
    width: 268px;
    height: 268px;
  }
}

/* iPhone SE in landscape */
@media only screen and (min-width: 320px) and (max-width: 567.98px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .okrugla-slika {
    max-width: 308px;
    max-height: 308px;
  }
  .border-outer {
    width: 310px;
    height: 310px;
  }
  .border-iner {
    width: 298px;
    height: 298px;
  }
}

/* iPad Pro in portrait */
@media (min-width: 1024px) and (max-width: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  .okrugla-slika {
    max-width: 316px;
    max-height: 316px;
  }
  .border-outer {
    width: 318px;
    height: 318px;
  }
  .border-iner {
    width: 306px;
    height: 306px;
  }
}
